import React from "react";
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import TextField from "@material-ui/core/TextField/TextField";
import SurveyTitleComp from './SurveyTitleComp';


export default function SurveySingleAndOtherChoiceComp({field, response, onSubmit, id}) {
    //console.log('SurveyMultipleChoiceComp');
    //console.log(response);
    //console.log(field);
    //console.log(field.properties.choices.map((choice) => choice.label.replace('- Note: Limited Options', '').replace(/[()]/g, "").trim().split(" ")))

    const [other, setOther] = React.useState((response && response.choice && response.choice.other) ? response.choice.other : null );
    const [checked, setChecked] = React.useState((response && response.choice && response.choice.ref) ? response.choice.ref : null);

    const generateResponse = () => {
        let result = {
            "type": "choice",
            "field": {
                "ref": field.ref,
                "type": "multiple_choice"
            },
            "choice": {}
        }
        if (checked) {
            let label = field.properties.choices.filter((choice) => checked == choice.ref).map((choice) => choice.label)[0];
            result["choice"]["ref"] = checked;
            result["choice"]["label"] = label;
            return result;
        }
        if (other) {
            result['choice']['id'] = 'other';
            result['choice']['other'] = other;
            return result;
        }
    }

    const verifyResponse = () => {
        if (!(field.validations && field.validations.required)) return true;
        if (other || checked) return true;
        return false;
    }

    const handleToggle = (value) => () => {
        setChecked(value);
        setOther(null);
    };

    const handleOtherSelect = () => {
        //console.log('handleOtherSelect');
        if (other == null) {
            setOther("");
            setChecked(null);
        }
    }

    const handleOtherDeselect = (event) => {
        //console.log('handleOtherDeselect');
        //console.log(event.target.checked);
        if (!event.target.checked) setOther(null);
    }

    const handleOtherFill = (event) => {
        //console.log('handleOtherFill');
        //console.log(event.target.value);
        setOther(event.target.value);
        setChecked(null);
    }

    const handleBlur = (event) => {
        if (other == '') setOther(null);
    }

    const onSaveResponse = () => {
        onSubmit(generateResponse());
    }

    return (
        <SurveyTitleComp
            id={id}
            field={field}
            onSubmit={onSaveResponse}
            submitDisabled={!verifyResponse()}
        >
            <FormControl fullWidth>
                <List >
                    {field.properties.choices.map((choice,i) => {
                        const labelId = `checkbox-list-label-${i}`;
                        return (
                            <ListItem key={i} role={undefined} dense button onClick={handleToggle(choice.ref)}>
                                <ListItemIcon>
                                    <Checkbox
                                        id={`typeform_checkbox_${i}_id`}
                                        edge="start"
                                        checked={checked == choice.ref}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={choice.label} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="comments">
                                        <CommentIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                    {
                        field.properties.allow_other_choice && (
                            <ListItem key={'other'} role={undefined} dense button onClick={handleOtherSelect}>
                                <ListItemIcon>
                                    <Checkbox
                                        id="typeform_other_checkbox_id"
                                        edge="start"
                                        checked={other != null}
                                        onChange={handleOtherDeselect}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': 'checkbox-list-label-other' }}
                                    />
                                </ListItemIcon>
                                {(other !== null)  && (
                                    <TextField
                                        id="typeform_other_textfield_id"
                                        value={other}
                                        onChange={handleOtherFill}
                                        onBlur={handleBlur}
                                        placeholder="Type your answer"
                                        fullWidth />)
                                }
                                {(other == null) && (
                                    <React.Fragment>
                                        <ListItemText primary="Other" />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="comments">
                                                <CommentIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </React.Fragment>)
                                }

                            </ListItem>
                        )
                    }
                </List>
            </FormControl>
        </SurveyTitleComp>
    )
}