import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {Card, CardMedia, Grid, Box} from '@material-ui/core'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {logout} from "react-devise/lib/actions";
import {connect} from "react-redux";
import {withAuth} from "react-devise";
import {Link} from 'react-router-dom';
import {fetchWithJWT} from "./FetchWithJWT";

import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 600,
        paddingTop: '100%',
        [theme.breakpoints.down('xs')]: {
            width: 410,
        },
    },
    feedback:{
        minWidth: 350,
    },

}));

function WelcomePage(props){

    const classes = useStyles();
    const [showMessageFlag, setShowMessageFlag] = useState(false);

    useEffect(() => {
      fetchWithJWT('/account/has_order_for_preview')
        .then((data) => { setShowMessageFlag(data['has_order_for_preview']) });
    }, []);

    return (
            <Grid container spacing={1} style={{paddingTop: '50px'}} alignContent={'flex-start'}>
                <Grid item xs={12} container justify={'center'} style={{marginBottom: 0}}>
                    <Grid item>
                    <Typography  variant="h5" style={{fontWeight: 'bold', color: '#525f7f'}}>
                        Welcome, {props.currentUser.firstName.split(" ")[0]}
                    </Typography>
                    </Grid>
                </Grid>
                { showMessageFlag && (
                    <React.Fragment>
                        <Grid item xs={12} container justify={'center'}>
                            <Link to="/preview">
                                <Grid item>
                                    <Typography  variant="body1" style={{color: '#525f7f'}}>
                                        Your shipment is ready for review
                                    </Typography>
                                </Grid>
                            </Link>
                        </Grid>
                        <Grid item xs={12} container justify={'center'}>
                            <Link to="/preview">
                                <Grid item>
                                    <Button size="large" variant="contained" color="secondary"  style={{textTransform: 'none'}}>&nbsp;&nbsp;Let's go!&nbsp;&nbsp;</Button>
                                </Grid>
                            </Link>
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item xs={12} container justify={'center'}>
                    <Box m={2} >
                        <Grid item >
                            <Link to="/preview">
                                <CardMedia
                                    image={"welcome3.png"}
                                    className={classes.cover}
                                />
                            </Link>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>

    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    };
};

export default connect(mapStateToProps)(WelcomePage);




